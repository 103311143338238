import * as React from "react"
import type { HeadFC } from "gatsby"
import { ContactForm } from "../components/ContactForm";
import '../styles/dnpg.css';
import diegoPelaezPhoto from '../images/diego-pelaez.png';
import instagram from '../images/instagram.png';
import linkedin from '../images/linkedin.svg';
import github from '../images/github.svg';
import githubDark from '../images/github-white.svg'
// import youtube from '../images/youtube.svg';
// import dnpgLogo from '../images/dnpg-logo.svg';

const IndexPage = () => {
  const [formOpened, setFormOpened] = React.useState(false);
  const nameRef = React.useRef<HTMLInputElement>(null);
  const showContactForm = () => {
    setFormOpened(!formOpened);
  }

  React.useEffect(() => {
    if (formOpened) {
      if (nameRef.current) {
        nameRef.current.focus();
      }
    }
  },[nameRef,formOpened])

  return (
    <main>
      <div className="card">
        <div className="diego-details">
          <div className="profile">
            <img width={194} height={194} src={ diegoPelaezPhoto } alt="Diego Pelaez" />
          </div>
          
          <div className="content">
            <h1>Hola, I'm Diego Pelaez</h1>
            <p>I'm a front-end web developer, <br/>expert in e-commerce and Shopify.</p>
            <p>With over 18 years of dev experience, <br/>I want to share with you all I’ve learnt.</p>
          </div>
        </div>
        <div className={`form${formOpened ? ' opened': ''}`}>
          <ContactForm/>
        </div>
      </div>
      <div className="socials">
        <ul className="socials__list">
          <li>
            <a href="https://github.com/dnpg" target="_blank">
              <picture>
                <source srcSet={githubDark} media="(prefers-color-scheme: dark)"/>
                <img src={github} alt="Check out my code on Github @dnpg" width={32} height={32}/>
              </picture>
            </a>
          </li>
          <li>
            <a href="https://instagram.com/dnpg.dev" target="_blank">
              <img src={instagram} alt="Follow me on Instagram @dnpg.dev" width={32} height={32}/>
            </a>
          </li>
          <li>
            <a href="https://www.linkedin.com/in/diegopego/" target="_blank">
              <img src={linkedin} alt="Follow me on Linkedin @diegopego" width={32} height={32}/>
            </a>
          </li>
        </ul>
        <div className="socials__contact">
          <button onClick={showContactForm} type="button" className="socials__contact-button">contact me</button>
        </div>
      </div>
    </main>
  )
}

export default IndexPage

export const Head: HeadFC = () => (
<>
  <title>Hola, I'm Diego Pelaez</title>
  <link rel="preconnect" href="https://fonts.googleapis.com"/>
  <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
  <link href="https://fonts.googleapis.com/css2?family=Niramit:wght@400;600&amp;display=swap" rel="stylesheet"></link>
</>);