import * as React from "react"
import { useFormSubmission } from "./utils"
import { FormField } from "../types/types"

const formConfig: FormField[] = [
  { initial: "", label: 'What is your name?', id: "form-name", name: "Name", type: "text", element: "input", required: "required" },
  { initial: "", label: 'What is your email?', id: "form-email", name: "Email", type: "email", element: "input", required: "required" },
  { initial: "", label: 'Leave a message', id: "form-message", name: "Message", type: "text", element: "textarea", required: "required" },
  {
    initial: "",
    name: "bot-field",
    type: "hidden",
    element: "input",
    className: "hidden"
  }
]

export const ContactForm = () => {
  const { formState, fieldsState, submitForm, updateField } = useFormSubmission(
    formConfig
  )
  if (formState === "SUCCESS") {
    return <div className="form__response success">Thanks! I&apos;ll be in touch shortly</div>
  }
  return (
    <form
      name="contact"
      onSubmit={submitForm}
      data-netlify="true"
      data-netlify-honeypot="bot-field"
    >
      {formConfig.map(({ id, label, name, element, type, className, required }) =>
      (<div className={ type !== 'hidden' ? 'field' : '' }>
        { label && label != '' && <label htmlFor={id}>{label}</label>}
        { React.createElement(element, {
          key: name,
          id,
          type,
          name,
          required,
          value: fieldsState[name],
          onChange: updateField,
          placeholder: name,
          className
        })}
        </div>)
      )}
      <input type="hidden" name="form-name" value="contact" />
      {formState === "SUBMITTING" && <div className="form__response submitting">Sending...</div>}
      {formState === "ERROR" && <div className="form__response error">Oops! Something went wrong!</div>}
      {formState !== "SUBMITTING" && <button type="submit">Send Message</button>}
    </form>
  )
}